import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import CustomClearanceFeatured from 'images/featured-image/customs-clearance.png';
import CustomClearanceImg from 'images/blog-images/customs-clearance.png';
import CustomsPolicyImg from 'images/blog-images/customs-policy.png';
import CustomsDeclarationsImg from 'images/blog-images/customs-declaration.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query CustomsClearanceImg {
      file(relativePath: { eq: "customs-clearance.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="custom clearance tips"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Customs Clearance Tips for Headache-Free International Shipping | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-customs-clearance-tips" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Customs Clearance Tips for Headache-Free International Shipping",
              content: intl.formatMessage({ id: "blog-post-meta-title-customs-clearance-tips" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: CustomClearanceFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="customs-clearance-tips-for-headache-free-international-shipping"
        title="Customs Clearance Tips for Headache-Free International Shipping"
        date="2020-08-04"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">
            <p>
              Packages that cross international borders are subject to more handling and scrutiny compared to domestic shipments, so packing and shipping them requires more care and consideration. People who do cross border eCommerce trade often complain about customs detention, especially when they need to send some product samples or small packages to their customers. If precautions are done properly, the chance of customs detention is extremely small. So, what are the reasons for deduction and how to avoid it? We will give a brief introduction on it. For specific inquiries, welcome to contact Shipkoo’s logistic specialist.
            </p>

            <h5>
              <strong>
                The followings are the main reasons for customs inspection and deduction:
              </strong>
            </h5>

            <OrderedList>
              <li>
                The declared value and the estimated price are inconsistent;
              </li>
              <li>
                The product name does not match the product;
              </li>
              <li>
                The packing list is unknown;
              </li>
              <li>
                Conditions of the consignee are not allowed (no import and export rights, etc.);
              </li>
              <li>
                The value of personal items exceeds RMB 5,000;
              </li>
              <li>
                Some relevant policies stipulated by the local country.
              </li>
            </OrderedList>

            <h5>
              <strong>
                How to avoid customs detention?
              </strong>
            </h5>

            <UnorderedList>
              <li>
                To avoid customs detention, general parcels can be written as personal gifts. The probability of private parcels being checked by the customs is very low.
              </li>
              <li>
                The declared value can be written relatively less, and the deduction rate of valuables is very high. Besides, after the customs detains the goods, the customs clearance fee is calculated based on the declared value. The higher the declared value, the higher the customs clearance fee. Similarly, if customers need to send back products, pay attention to ask customers to write down the declared value.
              </li>
              <li>
                Understand the policies of various countries. For example, although Australia is easy to pass customs, battery products are not allowed by the customs. Therefore, batteries or products with electromagnetic waves should not be sent to Australia. If you must sell products with batteries, you can make it clear to customers that you don’t ship batteries but only products.
              </li>
              <li>
                The heavier the package is, the more likely it is to be detained by the customs.
              </li>
              <li>
                Different products have different rates of being detained by the customs. For example, the probability of electronic products being detained is higher than that of clothing.
              </li>
              <li>
                The reporting strategy adopted for mailing to different countries is not different. The British and American customs are relatively less strict, and the declared value can be lowered appropriately; the German customs are stricter, so it is not appropriate to place a too low declared price; like Brazil, the customs are also very strict. Fill in the declaration materials carefully and accurately, otherwise it will be troublesome.
              </li>
            </UnorderedList>

            <Image src={CustomClearanceImg} className="" alt="how to avoid customs detention"/>

            <p>
              The above reminders are to reduce the probability of being detained, and it is impossible to completely eliminate it.
            </p>

            <h5>
              <strong>
                The followings are some countries’ customs policies for reference:
              </strong>
            </h5>

            <p>
              <span className="text-green">Brazil</span>: Based on current statistics and market research, it is the country with the strictest customs clearance policy. Also, you need to provide the VAT registration number of the recipient;
            </p>

            <p>
              <span className="text-green">Mexico</span>: Customs clearance is relatively easy, but if a piece is checked by the customs, it will take a long time to return to the sender without special customs inspection abnormalities;
            </p>

            <p>
              <span className="text-green">United States</span>: The United States is a country that advocates online shopping. It is faster to express parcels and is exempt from customs duties for packages less than $200. At the same time, the United States has a relatively strict inspection system for safety and personal health products. 
            </p>

            <p>
              <span className="text-green">Canada</span>: There are no restrictions on certain categories of products, but the inspection of the declared value is relatively strict.
            </p>

            <p>
              <span className="text-green">Australia</span>: The inspection of imported parcels is relatively loose, and customs duties are exempted for packages less than 1,000 Australian dollars. Customs clearance is relatively easy except for some prohibited and log products;
            </p>

            <p>
              <span className="text-green">Singapore</span>: Tariffs are levied on packages with a declared amount greater than 400 Singapore dollars (around 320 USD);
            </p>

            <p>
              <span className="text-green">Philippines</span>: There is no set exemption limit for imported goods. Duties may be imposed on all kinds of packages, so there is uncertainty about taxation.
            </p>

            <p>
              <span className="text-green">Japan</span>: Tax exemption for packages with a value of less than 1,000 yen. According to actual operating data, there are fewer packages being checked in Japan;
            </p>

            <p>
              <span className="text-green">United Kingdom</span>: Tariffs will be imposed when the declared value is greater than 18GBP. The United Kingdom belongs to the European Union (although it has voted to Brexit ), but it is a relatively special one in the European Union. We rarely find packages in the UK by customs;
            </p>

            <p>
              <span className="text-green">Germany</span>: It belongs to the European Union and will levy taxes on parcels greater than 22 euros. Customs inspection in Germany is stronger than other European Union countries;
            </p>

            <p>
              <span className="text-green">France</span>: It belongs to the European Union, and taxation is imposed on parcels greater than 22 euros. According to our data, there are only a small number of parcels being checked by the customs.
            </p>

            <Image src={CustomsPolicyImg} alt="customs policies"/>

            <p>
              Different countries have different requirements and regulations on the import and export of goods. We need to understand the import and export details of each country to avoid problems at critical times. The followings are some brief introduction:
            </p>

            <h5>
              <strong>
                The followings are some countries’ customs policies for reference:
              </strong>
            </h5>

            <UnorderedList>
              <li>
                United States
              </li>
              <li>
                Canada
              </li>
              <li>
                Mexico
              </li>
              <li>
                Philippines
              </li>
            </UnorderedList>

            <p>
              The U.S. does not need to declare the ISF regulations and must be provided to the U.S. Customs 48 hours before departure, otherwise there will be a fine of USD5000, AMS fee of 25 USD/ticket, and 40 USD/ticket if modified. Since July 1, 2016, all goods imported into the Philippines must be declared AMS in advance. In addition to the original EBS in the Philippines, CIC will add an AMS surcharge. Goods to the Philippines require AMS declaration in advance.
            </p>

            <h5>
              <strong>
                Countries that need to declare ENS
              </strong>
            </h5>

            <UnorderedList>
              <li>
                All EU members <br/>
                ENS costs 25-35 USD/ticket.
              </li>
            </UnorderedList>

            <h5>
              <strong>
                Countries where wooden packaging needs to be fumigated
              </strong>
            </h5>
            <UnorderedList>
              <li>
                Australia
              </li>
              <li>
                United States
              </li>
              <li>
                Canada
              </li>
              <li>
                South Korea
              </li>
              <li>
                Japan
              </li>
              <li>
                Indonesia
              </li>
              <li>
                Malaysia
              </li>
              <li>
                Philippines
              </li>
              <li>
                Israel
              </li>
              <li>
                Brazil
              </li>
              <li>  
                Chile
              </li>
              <li>
                Panama
              </li>
            </UnorderedList>

            <h5>
              <strong>
                Countries requiring a certificate of origin
              </strong>
            </h5>
            <UnorderedList>
              <li>
                Cambodia
              </li>
              <li>
                Canada
              </li>
              <li>
                Bahrain
              </li>
              <li>
                Saudi Arabia
              </li>
              <li>
                Egypt
              </li>
              <li>
                Bangladesh
              </li>
              <li>
                Sri Lanka
              </li>
            </UnorderedList>

            <Image src={CustomsDeclarationsImg} alt="customs declaration"/>

            <p>
              Properly completed customs forms will help prevent delays and get the item to the recipient safely when your deliveries require international shipping. It may seem that you need to do a lot to fulfill the requirements of different countries’ customs. Don’t worry, Shipkoo handles your shipments in a smarter way and you only need to focus on growing business instead of worrying about back-end issues.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;